<template>
  <div class="user-profile grid ggap-30">
    <!-- Basic -->
    <section class="grid ggap-10">
      <h3 class="title">Общие сведения:</h3>
      <div class="grid ggap-10">
        <div class="user-profile__row grid ggap-20">
          <span class="t-grey-dark">Статус:</span>
          <span>{{ user.status || 'Не прикаенная душа' }}</span>
        </div>
        <div class="user-profile__row grid ggap-20">
          <span class="t-grey-dark">Комания:</span>
          <span>{{ user.company || 'Без работная душа' }}</span>
        </div>
        <div class="user-profile__row grid ggap-20">
          <span class="t-grey-dark">Специальность:</span>
          <span>{{ user.specialization || 'Роннин' }}</span>
        </div>
      </div>
    </section>

    <!-- Contacts -->
    <section class="grid ggap-10">
      <h3 class="title">Контакты:</h3>
      <div v-if="user.contacts && user.contacts.length" class="user-profile__contacts flex ai-c ggap-10 fw">
        <span
          v-for="contact of user.contacts"
          :key="contact.id"
          class="box pt-5 pb-5 pl-10 pr-10 fs-14 flex ai-c ggap-5"
        >
          <BaseIcon class="ic-18 primary" :icon="contact.type" />
          {{ contact.value }}
        </span>
      </div>
      <BaseAlert v-else type="info"> Видимо еще нет доступных контактных данных </BaseAlert>
    </section>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import {
  BaseIcon,
  BaseAlert
  // FormInput,
  // FormSelect
  // BaseLoad
} from '@/components'

// Data
const store = useStore()

// Computed
const user = computed(() => {
  return store.getters?.user
})
</script>

<style lang="scss" scoped>
.user-profile {
  &__row {
    grid-template-columns: 120px 1fr;
  }
}
</style>
